<template>
    <div class="assessement-preview" v-if="this.showMyAssessmentDetails">
        <InviteCandidate :showEmail="showEmail" :toggleEmail="toggleEmail" :jobId="assessment._id" />
        <div class="wrapper">
            <div class="success-score">
                <div>
                    <h1>{{ $t("Set Success Score") }}</h1>
                    <div class="slider">
                        <input
                            type="range"
                            min="1"
                            max="100"
                            v-model="sliderValue"
                            id="success-slider"
                            :style="{ background: `linear-gradient(90deg, #2196f3 ${sliderValue}%, #FFF ${sliderValue}%)` }"
                        />
                        <div class="success-score-input">
                            <input type="number" min="1" max="100" v-model="sliderValue" id="success-score" />
                            <span>%</span>
                        </div>
                    </div>
                </div>
                <button @click="updateScore" class="validate-button">{{ $t("Validate") }}</button>
            </div>

            <div class="grid-row-1">
                <div class="test-compisition">
                    <div class="top-section">
                        <div class="text">
                            <h1>{{ $t("Test Composition") }}</h1>
                            <span>{{ $t("Time") }} : {{ assessment.duration }} </span>
                            <span>Questions : {{ assessment.qst_num }} </span>
                            <span>{{ $t("30 multiple choice questions") }}</span>
                        </div>
                        <div class="chart-section">
                            <div class="on-track">
                                <span>
                                    <img loading="lazy" decoding="async" src="@/assets/Images/icons/check-green.svg" alt="on-treack" />
                                    {{ $t("On Track") }}</span
                                >
                                <span><img loading="lazy" decoding="async" src="@/assets/Images/icons/bar-chart.svg" alt="bar-chart" /></span>
                            </div>
                            <div class="chart">
                                <img loading="lazy" decoding="async" src="@/assets/Images/icons/chart.svg" alt="chart" />
                            </div>
                        </div>
                    </div>
                    <div class="bottom-section">{{ $t("Tests included in the Assessment") }}</div>
                </div>
                <div class="recent-candidats">
                    <h1>{{ $t("Recent Candidates") }}</h1>
                    <RacentCandidateRow v-for="i in 3" :key="i" />
                </div>
            </div>
            <div class="preview-btn">
                <button @click="togglePreview">{{ $t("Cancel") }}</button>
                <button @click="toggleEmail()">{{ $t("Invite") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
// import LibraryCard from "@/components/LibraryCard.vue";
import RacentCandidateRow from "@/components/RacentCandidateRow.vue";
import InviteCandidate from "@/components/dashboard/project-board/InviteCandidate.vue";
import axios from "axios";
export default {
    name: "MyAssessmentDetails",
    components: { RacentCandidateRow, InviteCandidate },
    props: ["assessment", "showMyAssessmentDetails", "togglePreview", "addAssessment"],
    data() {
        return {
            sliderValue: this.assessment.min_score !== 0 ? this.assessment.min_score : this.assessment.recommanded_score,
            showEmail: false,
        };
    },
    methods: {
        toggleEmail() {
            this.showEmail = !this.showEmail;
        },
        initializeSlider() {
            // Access the slider and output elements here
            // Update the sliderValue and set styles
            const slider = document.getElementById("success-slider");
            const output = document.getElementById("success-score");

            if (slider && output) {
                output.value = this.sliderValue;

                slider.oninput = () => {
                    slider.style.background = `linear-gradient(90deg , #2196f3 ${slider.value}%, #FFF ${slider.value}%)`;
                    output.value = slider.value;
                };

                output.oninput = () => {
                    slider.value = output.value;
                    slider.style.background = `linear-gradient(90deg , #2196f3 ${output.value}%, #FFF ${output.value}%)`;
                };
            }
        },
        updateSliderBackground(value) {
            const slider = document.getElementById("success-slider"); // Use class selector
            if (slider) {
                slider.style.background = `linear-gradient(90deg, #2196f3 ${value}%, #FFF ${value}%)`;
            }
        },
        updateScore() {
            // Update the score here
            let data = JSON.stringify({
                fieldToUpdate: "min_score",
                newValue: this.sliderValue,
            });
            let assessmentId = this.assessment._id;
            let config = {
                method: "put",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/AssessmentTest/updateScore/${assessmentId}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios
                .request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    watch: {
        showDetails(newVal) {
            if (newVal) {
                // Ensure you access the elements only when showDetails is true
                this.initializeSlider();
            }
        },
        sliderValue(newVal) {
            this.updateSliderBackground(newVal);
        },
    },
};
</script>

<style lang="scss" scoped>
.assessement-preview {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #0000003f;
    align-items: center;
    /*grid-template-areas: 
      'head  head'
      'description  cards'
      'btns btns';
      grid-template-rows: 1fr 3fr 1fr;
      grid-template-columns: 5fr 2fr;*/

    .wrapper {
        background: #f4f7fe;
        padding: 20px;

        border-radius: 50px;
        position: absolute;
    }

    // .preview-close {
    //   position: fixed;
    //   z-index: -1;
    //   bottom: 0;
    //   top: 0;
    //   right: 0;
    //   left: 0;
    //   background: rgba(0, 0, 0, 0.4);
    // }
    > :nth-child(4) {
        border-radius: 10px;
        padding: 2rem 3rem;
        width: 50%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        background: #fff;
        align-self: center;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.04);
        gap: 1rem;
        color: #050708;

        .preview-description {
            display: flex;
            flex-direction: column;
            //grid-area: description;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            > span {
                font-weight: 400;
                padding: 1rem;
            }
        }

        .preview-cards {
            //grid-area: cards;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            gap: 1rem;
            /*border: 1px solid #C3CAD9;
          border-radius: 20px;
          box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);*/
            padding: 1rem 0;

            > * {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                align-items: center;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    height: 2rem;

                    font-weight: 700;
                    font-size: 30px;
                    line-height: 22px;
                    text-align: center;
                    padding: 0.5rem;
                    color: #2196f3;
                }
            }
        }
    }
}

.icon {
    font-size: 6rem;
    color: #2196f3;
}

.preview-btn {
    //grid-area: btns;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45px;
    justify-content: center;
    gap: 1rem;
    align-items: flex-end;

    button {
        width: 18%;
        height: 100%;
        padding: 0.5rem;
        border-radius: 5px;
        color: #fff;
        background: #2196f3;
        border: none;
        font-weight: 500;
        font-size: 22px;
        line-height: 20px;
        text-align: center;
        border-radius: 20px;
        font-weight: 700;
        transition: all 0.3s ease-in-out;

        &:hover {
            cursor: pointer;
            font-size: 20px;
        }
    }

    > :first-child {
        background: #fff;
        box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.04);
        color: #050708;
        border: 1px solid #c3cad9;
    }
}

.title-section {
    display: flex;
    align-items: center;
    margin-top: 48px;
}

h1 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    color: #656669;
    margin-right: 56px;
}

.searchbar {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
}

::placeholder {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 9px;
    color: #adb8cc;
}

.cards {
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
}

.assessments {
    padding: 24px 8px 24px 24px;
    display: grid;
    grid-template-columns: 27% 27% 27% auto;
    align-items: flex-start;
    gap: 16px;
}

.validate-button {
    background: #2196f3;
    border: none;
    border-radius: 70px;
    padding: 12px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    width: 100%;
    height: fit-content;
    margin-bottom: 5px;
}

.success-score {
    display: grid;
    grid-template-columns: 6fr 1fr;
    padding-left: 48px;
}

.slider {
    display: flex;
    align-items: center;
}

.success-score-input {
    background: #fff;
    border: 1px solid #e5e5ef;
    border-radius: 12px;
    padding: 8px;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
}

#success-slider {
    -webkit-appearance: none;
    appearance: none;
    // background: linear-gradient(90deg, #2196f3 70%, #fff 70%);
    border: 1px solid #2196f3;
    border-radius: 90px;
    margin-right: 48px;
    width: 35vw;
    height: 16px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #2196f3;
    cursor: pointer;
}

#success-score {
    border: none;
    margin: 0;
    font-family: "Roboto";
    font-weight: 500;
    width: 56px;
    font-size: 24px;
    text-align: center;
    color: #000000;
}

input {
    border: none;
}

input:focus {
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.grid-row-1 {
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 75px;
    padding: 24px;
}

.grid-row-1 > div > h1 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 24px;
}

.test-compisition {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 20px;
}

.top-section {
    display: flex;
    justify-content: space-between;
}

.top-section > .text {
    display: flex;
    flex-direction: column;
    color: #000000;
}

.top-section > .text > * {
    margin-bottom: 24px;
}

.top-section > .text > h1 {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 20px;
    color: #1b2559;
    margin-bottom: 24px;
}

.on-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 16px;
    color: #05cd99;
}

.chart {
    margin: 16px 8px;
}

.recent-candidats {
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 20px;
}
</style>
