<template>
    <div v-if="show" class="container">
        <div @click="toggleModal" class="exit_zone"></div>
        <div class="payement_popup">
            <div>
                <span>{{ $t("Control your Payments") }}</span>
                <button class="btn"><img loading="lazy" decoding="async" src="@/assets/Images/icons/Filter.svg" alt="Filters" height="30" width="25" />{{ $t("Filters") }}</button>
            </div>
            <div class="table_container">
                <div>
                    <span class="">{{ $t("Name") }}/{{ $t("Client") }}</span>
                    <span class="">Date</span>
                    <span class="">{{ $t("Invoice ID") }}</span>
                    <span class="">{{ $t("Amount") }}</span>
                    <span class="">Status</span>
                    <span class="">{{ $t("Action") }}</span>
                </div>
                <div class="payement_infos" v-for="(payement, index) in payementList" :key="index">
                    <span class=""><img loading="lazy" decoding="async" :src="require(`@/assets/Images/icons/${payement.imagepath}`)" width="50" height="30" />{{ payement.name }}</span>
                    <span class=""
                        >{{ payement.date }} <span>{{ $t("at") }} {{ payement.time }}</span></span
                    >
                    <span class="">{{ payement.invoice_ID }}</span>
                    <span class="">{{ payement.amount }}$</span>
                    <span class="status" :class="payement.status"></span>
                    <button class="btn">...</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PayementControl",
    props: ["show", "toggleModal"],
    data() {
        return {
            payementList: [
                {
                    imagepath: "Payment_paypal.svg",
                    name: "Gadget Gallerie LTD",
                    date: "14/03/2022",
                    time: "08:26pm",
                    invoice_ID: "0197328",
                    amount: "1200",
                    status: "Pending",
                },
                {
                    imagepath: "Payment_mastercard.svg",
                    name: "Gadget Gallerie LTD",
                    date: "14/03/2022",
                    time: "08:26pm",
                    invoice_ID: "0197328",
                    amount: "1200",
                    status: "Stopped",
                },
                {
                    imagepath: "Payment_paypal.svg",
                    name: "Gadget Gallerie LTD",
                    date: "14/03/2022",
                    time: "08:26pm",
                    invoice_ID: "0197328",
                    amount: "1200",
                    status: "Pending",
                },
                {
                    imagepath: "Payment_Apple.svg",
                    name: "Gadget Gallerie LTD",
                    date: "14/03/2022",
                    time: "08:26pm",
                    invoice_ID: "0197328",
                    amount: "1200",
                    status: "Stopped",
                },
                {
                    imagepath: "Payment_visa.svg",
                    name: "Gadget Gallerie LTD",
                    date: "14/03/2022",
                    time: "08:26pm",
                    invoice_ID: "0197328",
                    amount: "1200",
                    status: "In Progress",
                },
                {
                    imagepath: "Payment_stripe.svg",
                    name: "Gadget Gallerie LTD",
                    date: "14/03/2022",
                    time: "08:26pm",
                    invoice_ID: "0197328",
                    amount: "1200",
                    status: "Pending",
                },
                {
                    imagepath: "Payment_visa.svg",
                    name: "Gadget Gallerie LTD",
                    date: "14/03/2022",
                    time: "08:26pm",
                    invoice_ID: "0197328",
                    amount: "1200",
                    status: "Stopped",
                },
                {
                    imagepath: "Payment_stripe.svg",
                    name: "Gadget Gallerie LTD",
                    date: "14/03/2022",
                    time: "08:26pm",
                    invoice_ID: "0197328",
                    amount: "1200",
                    status: "In Progress",
                },
                {
                    imagepath: "Payment_visa.svg",
                    name: "Gadget Gallerie LTD",
                    date: "14/03/2022",
                    time: "08:26pm",
                    invoice_ID: "0197328",
                    amount: "1200",
                    status: "In Progress",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.container {
    font-family: "inter";
    font-style: normal;
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    .payement_popup {
        width: 70%;
        height: 75%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 2%;
        background: #fff;
        border-radius: 6px;

        div {
            display: flex;
            //flex-direction: row;
            //justify-content: space-between;
            width: 100%;
        }

        > :first-child {
            font-weight: 700;
            font-size: 25px;
            line-height: 33px;
            color: #1b212d;
            margin-bottom: 3rem;
            display: flex;
            justify-content: space-between;
        }

        .table_container {
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow-y: scroll;

            > :first-child {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #929eae;
                padding: 1rem 0;
                display: grid;
                grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
            }

            .payement_infos {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #1b212d;
                display: grid;
                grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
                padding: 1.25rem 0;
                border-bottom: 1px solid #f5f5f5;
                // width: 98%;

                * {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                }

                > :first-child {
                    justify-content: flex-start;
                }

                > :nth-child(2) {
                    display: flex;
                    flex-direction: column;

                    span {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        color: #929eae;
                        text-align: center;
                    }
                }

                > :nth-child(3) {
                    color: #78778b;
                }

                > :nth-child(4) {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #1b212d;
                }

                button {
                    justify-self: baseline;
                    align-self: center;
                    border: none;
                    padding: 0 25%;
                }

                button:hover {
                    font-size: 20px;
                }
            }
        }
    }
}

.status {
    border-radius: 4px;
    z-index: 100;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.Pending::after {
    background: #fff1e6;
    content: "Pending";
    color: #f2994a;
    padding: 5%;
    border-radius: 5px;
}

.Stopped::after {
    background: #ffefef;
    color: #eb5757;
    content: "Stopped";
    padding: 5%;
    border-radius: 5px;
}

.Progress::after {
    color: #27ae60;
    background: #d9ffe9;
    content: "In Progress";
    padding: 5%;
    border-radius: 5px;
}

.btn {
    background: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    width: 100%;
}

.btn:hover {
    font-size: 16px;

    img {
        width: 30px;
    }
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #dbdbdc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #b8b8b9;
}

.exit_zone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
</style>
