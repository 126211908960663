<template>
    <div v-if="showUpgrade" class="fixed top-0 left-0 w-full z-[12] h-full bg-[#00000086] flex justify-center items-center">
        <div class="bg-[#fff] rounded-[10px] px-6 py-10 shadow-lg w-[35%]">
            <div>
                <h1 class="text-[20px] font-[700]">{{ $t("Discover premium features") }}</h1>
                <h3 class="text-[14px] font-md my-6">{{ $t("Upgrade to the Starter plan to use this feature. You can learn more about Creating an assessment here.") }}</h3>
                <div class="flex justify-end items-center gap-4">
                    <ButtonComponent
                        :action="
                            () => {
                                toggleUpgrade();
                            }
                        "
                        intent="secondary"
                    >
                        {{ $t("Cancel") }}
                    </ButtonComponent>
                    <ButtonComponent
                        :action="
                            () => {
                                this.Store.toggleSubscriptions();
                            }
                        "
                        intent="primary"
                    >
                        {{ $t("Upgrade") }}
                    </ButtonComponent>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from "@/components/ReusableComponents/ButtonComponent.vue";
import { useStore } from "@/store/index.js";

export default {
    name: "UpgradeRequest",
    components: {
        ButtonComponent,
    },
    props: {
        showUpgrade: {
            type: Boolean,
            default: false,
        },
        toggleUpgrade: {
            type: Function,
        },
    },
    data() {
        return {
            newQst: true,
            selectedQst: {},
            previewQst: false,
        };
    },
    methods: {
        editQst() {
            this.$emit("editQst", this.selectedQst);
            this.newQst = false;
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
};
</script>
