<template>
    <div class="recruiter">
        <div class="flex flex-wrap">
            <div class="flex flex-wrap">
                <div class="mr-3" style="width: 65px">
                    <img :src="require(`../assets/guy-two.png`)" />
                </div>
                <div class="information">
                    <div class="flex">{{ name }}</div>
                    <div class="flex"><Strong>Recruiter</Strong></div>
                    <div class="flex" style="color: #c2c5c6">{{ country }}</div>
                </div>
            </div>

            <div class="flex" style="margin-left: auto !important"></div>

            <div class="flex">
                <a class="view-profile">View profile</a>
            </div>
        </div>

        <div class="flex flex-wrap gap-8" style="margin-top: 20px">
            <div class="text">£25/hr</div>

            <div class="text">100% Job Success</div>

            <div class="text">£100K+ earned</div>
        </div>

        <div class="flex flex-wrap gap-8" style="margin-top: 20px">
            <div class="text-2">Recruting</div>
            <div class="text-2">IT Recruting</div>
            <div class="text-2">Human Resource Management</div>
        </div>

        <div class="desc">
            {{ desc }}
        </div>

        <div style="text-align: left; margin-top: 20px; margin-bottom: 50px">
            <strong style="font-weight: 500">Aymen has worked </strong><span style="color: #2196f3">23 jobs related to your search</span>
        </div>

        <hr class="h-[5px] bg-[#F5F8FF] w-full" />
    </div>
</template>

<script>
export default {
    name: "MarketPlaceCard",
    props: {
        country: { type: String, required: true },
        name: { type: String, required: true },
        desc: { type: String, required: true },
    },
};
</script>

<style lang="scss" scoped>
.recruiter {
    word-wrap: break-word;
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    cursor: pointer;
}

.information {
    flex-direction: column;
    display: flex;
    font-size: 17px;
}

.text {
    color: #676767;
    font-size: 17px;
}

.text-2 {
    background-color: #e9e9e9;
    color: #676767;
    padding: 5px 15px;
    border-radius: 16px;
    font-size: 14px;
}

.desc {
    margin-top: 20px;
    color: #676767;
    font-size: 16px;
    text-align: left;
}
.view-profile {
    padding: 10px 24px;
    color: #2196f3;
    height: fit-content;
    border-radius: 11px;
    border: 2px solid #2196f3;
}
</style>
