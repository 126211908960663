<template>
    <header v-if="this.Store.getisLogged !== 'admin'">
        <NotificationWindow :showNotif="showNotif" :toggleNotif="toggleNotif" />
        <nav class="navbar" :class="{ expend: !SidebarViewOpen }" :style="{ 'margin-left': '0', width: '100%' }">
            <div class="w-full flex flex-col lg:flex-row">
                <img @click="this.$router.push('/dashboard')" src="@/assets/Images/go_logo-copy.svg" alt="Recruitable" width="60" height="40" class="thelogo hidden lg:block" />
                <div class="w-full flex flex-row justify-between items-center lg:hidden px-5 smallNav">
                    <img @click="this.$router.push('/dashboard')" src="@/assets/Images/go_logo-copy.svg" alt="Recruitable" width="40" height="25" class="thelogo" />
                    <div class="relative">
                        <div class="relative">
                            <div class="account-name cursor-pointer rounded-[50%] border-[1px] border-[#2196f3] text-[#fff] p-2 hover:text-[#2196f3] hover:bg-[#fff] duration-300" @click="toggleMenu">
                                {{ this.userInitials }}
                            </div>
                            <div v-if="showMenu" class="flex flex-col justify-between absolute w-[180px] rounded-md h-[fit-content] z-10 bg-[#fff] top-[120%] border-[#EAEAEA] right-0 shadow-card">
                                <div class="fixed top-0 left-0 w-full h-full z-[-1] bg-transparent" @click="toggleMenu"></div>
                                <div class="flex flex-col w-full" style="gap: 0">
                                    <div class="relative cursor-pointer w-full py-2 px-3 hover:bg-[#F3F4F6]" @click="toggleShowCompanies">
                                        <span class="w-[95%] flex justify-between items-center">
                                            {{ this.Store.company_name }}
                                            <font-awesome-icon class="text-[14px]" :icon="['fas', 'angle-right']" />
                                        </span>

                                        <!-- </div> -->
                                    </div>
                                    <div class="cursor-pointer w-full py-2 px-3 hover:bg-[#F3F4F6]">
                                        <router-link to="/manage" class="text-[14px]">{{ $t("Manage") }}</router-link>
                                    </div>
                                    <div class="cursor-pointer w-full py-2 px-3 hover:bg-[#F3F4F6]">
                                        <router-link to="/payout" class="text-[14px]">{{ $t("Payout") }}</router-link>
                                    </div>
                                </div>
                                <div class="cursor-pointer w-full py-2 px-3 hover:bg-[#F3F4F6]">
                                    <span class="text-[14px]" @click="logOut">{{ $t("Sign out") }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <SwitchLanguage /> -->
                    </div>
                </div>
                <div class="nav-links text-lg font-medium h-full w-full lg:w-fit flex flex-row justify-between items-center">
                    <router-link to="/dashboard" class="link-hover w-12 lg:w-fit lg:h-fit h-12 flex justify-center items-center" :class="`${this.$route.path == '/dashboard' ? 'active ' : ''}`">
                        <span class="hidden lg:block">{{ $t("Projects") }}</span>
                        <font-awesome-icon class="lg:hidden block" :icon="['far', 'folder-closed']" />
                    </router-link>
                    <router-link
                        to="/candidates"
                        class="link-hover w-12 lg:w-fit lg:h-fit h-12 flex justify-center items-center relative disabled"
                        :class="`${this.$route.path == '/candidates' ? 'active ' : ''}`"
                    >
                        <div class="absolute top-[-15px] right-[-25px] rounded-[15px] text-[12px] px-2 text-[#fff] bg-[#2371b6]">Soon</div>
                        <span class="hidden lg:block"> {{ $t("Marketplace") }}</span>
                        <font-awesome-icon class="lg:hidden block" :icon="['far', 'user']" />
                    </router-link>
                    <router-link to="/library" class="link-hover w-12 lg:w-fit lg:h-fit h-12 flex justify-center items-center" :class="`${this.$route.path == '/library' ? 'active ' : ''}`">
                        <span class="hidden lg:block">{{ $t("Library") }}</span>
                        <font-awesome-icon class="lg:hidden block" :icon="['far', 'file-lines']" />
                    </router-link>
                    <button @click="toggleNotif" class="block lg:hidden bell-btn link-hover w-12 lg:w-fit lg:h-fit h-12 flex justify-center items-center">
                        <font-awesome-icon :icon="['fas', 'bell']" />
                    </button>
                </div>
            </div>
            <div>
                <span class="whitespace-nowrap font-semibold text-l">{{ this.Store.companyPlan == "pay-per-use" ? $t("credit") + ": " + this.Store.companyCredit : "" }} </span>
                <a
                    href="https://calendly.com/aouf-abdellah/20min"
                    target="_blank"
                    class="whitespace-nowrap font-semibold border border-CustomBlue rounded-md text-CustomBlue hover:bg-CustomBlue hover:text-white duration-300 px-4 py-2"
                    >Book a demo</a
                >
                <a href="https://calendly.com/aouf-abdellah/20min" target="_blank" v-if="!this.Store.premium" class="viewbtn flex items-center gap-2 h-full px-4 py-2 duration-500">
                    <svg width="20px" height="20px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                        <title>ionicons-v5-p</title>
                        <path
                            d="M461.81,53.81a4.4,4.4,0,0,0-3.3-3.39c-54.38-13.3-180,34.09-248.13,102.17a294.9,294.9,0,0,0-33.09,39.08c-21-1.9-42-.3-59.88,7.5-50.49,22.2-65.18,80.18-69.28,105.07a9,9,0,0,0,9.8,10.4l81.07-8.9a180.29,180.29,0,0,0,1.1,18.3,18.15,18.15,0,0,0,5.3,11.09l31.39,31.39a18.15,18.15,0,0,0,11.1,5.3,179.91,179.91,0,0,0,18.19,1.1l-8.89,81a9,9,0,0,0,10.39,9.79c24.9-4,83-18.69,105.07-69.17,7.8-17.9,9.4-38.79,7.6-59.69a293.91,293.91,0,0,0,39.19-33.09C427.82,233.76,474.91,110.9,461.81,53.81ZM298.66,213.67a42.7,42.7,0,1,1,60.38,0A42.65,42.65,0,0,1,298.66,213.67Z"
                        />
                        <path d="M109.64,352a45.06,45.06,0,0,0-26.35,12.84C65.67,382.52,64,448,64,448s65.52-1.67,83.15-19.31A44.73,44.73,0,0,0,160,402.32" />
                    </svg>
                    Upgrade
                </a>
                <div class="profile-section relative hidden lg:block">
                    <div
                        v-if="showCompanies"
                        @mouseenter="showCompanies = true"
                        @mouseleave="showCompanies = false"
                        class="w-[150px] absolute left-[21%] top-[6rem] text-left shadow-card top-[120%] bg-[#fff] p-1 rounded flex flex-col items-start"
                        style="z-index: 9999; align-items: flex-start; gap: 0"
                    >
                        <span
                            class="!group cursor-pointer !text-gray-900 hover:!bg-[#2196f3] hover:!text-white !flex !flex-col !w-full !font-extralight !rounded-md !px-2 !py-2 !text-left !text-sm"
                            v-for="(company, index) in companies"
                            :key="index"
                            @click="switchCompany(company)"
                            >{{ company }}</span
                        >
                    </div>
                    <DropdownComponent class="dropdown-component z-30" align="right" width="15rem">
                        <button>
                            <div class="account-name cursor-pointer rounded-[50%] border-[1px] border-[#2196f3] text-[#fff] p-2 hover:opacity-80 hover:bg-[#fff] duration-300">
                                {{ this.userInitials }}
                            </div>
                        </button>

                        <template #items>
                            <DropdownItemGroup label="Company">
                                <DropdownItem @mouseenter="showCompanies = true" @mouseleave="showCompanies = false">
                                    {{ this.Store.company_name }} <font-awesome-icon class="text-[14px]" :icon="['fas', 'angle-right']" />
                                </DropdownItem>
                                <DropdownItem
                                    :action="
                                        () => {
                                            toggleMenu();
                                            this.$router.push('/settings');
                                        }
                                    "
                                    >{{ $t("Profile Settings") }}</DropdownItem
                                >
                            </DropdownItemGroup>
                            <DropdownItemGroup>
                                <DropdownItem :action="logOut">{{ $t("Sign out") }}</DropdownItem>
                            </DropdownItemGroup>
                        </template>
                    </DropdownComponent>
                    <button @click="toggleNotif" class="!border-none hover:text-blue hover:bg-blue flex items-center justify-center mx-4 !p-0">
                        <font-awesome-icon class="!h-[20px] !bg-blue !text-blue !p-0 !w-[20px]" :icon="['far', 'bell']" />
                    </button>

                    <!--  <div v-if="showMenu"
                        class="flex flex-col justify-between absolute w-[180px] z-10 rounded-md h-[fit-content] bg-[#fff] top-[120%] border-[#EAEAEA] right-0 shadow-card">
                        <div class="fixed top-0 left-0 w-full h-full z-[-1] bg-transparent" @click="toggleMenu"></div>
                        <div class="flex flex-col w-full" style="gap: 0">
                            <div class="relative cursor-pointer w-full py-2 px-3 hover:bg-[#F3F4F6]"
                                @click="toggleShowCompanies">
                                <span class="w-[95%] flex justify-between items-center">
                                    {{ this.Store.company_name }}
                                    <font-awesome-icon class="text-[14px]" :icon="['fas', 'angle-right']" />
                                </span>
                                <div v-if="showCompanies"
                                    class="w-[150px] absolute left-[-155px] text-left shadow-card top-0 bg-[#fff] rounded flex flex-col items-start"
                                    style="z-index: 15; align-items: flex-start; gap: 0">
                                    <span class="p-2 w-full hover:bg-[#F3F4F6] duration-300"
                                        v-for="(company, index) in companies" :key="index"
                                        @click="switchCompany(company)">{{ company }}</span>
                                </div>
                            </div>
                            
                        </div>
                        <div class="cursor-pointer w-full py-2 px-3 hover:bg-[#F3F4F6]">
                            <span class="text-[14px]" @click="() => { toggleMenu(); this.$router.push('/settings'); } ">Profile Settings</span>
                        </div>
                        <div class="cursor-pointer w-full py-2 px-3 hover:bg-[#F3F4F6]">
                            <span class="text-[14px]" @click="logOut">Sign out</span>
                        </div>
                    </div>-->
                    <SwitchLanguage />
                </div>
            </div>
        </nav>
    </header>
    <header v-else>
        <nav class="navbar">
            <div class="first section">
                <button class="nav-btn logo-btn" @click="this.toggleSideBarView">
                    <font-awesome-icon :icon="['fas', 'bars']" class="nav-icon logo-icon" />
                </button>

                <router-link to="/"><img loading="lazy" decoding="async" src="@/assets/Images/go_logo.svg" alt="Recruitable" width="150" height="40" class="thelogo" /></router-link>
            </div>
            <h1 v-if="this.$route.path == '/admin'">Dashboard</h1>
            <h1 v-if="this.$route.path == '/adminBlogs'">Blogs</h1>
            <h1 v-if="this.$route.path == '/addBlogs'">Blogs - Add</h1>
            <h1 v-if="this.$route.name == 'updateBlog'">Blogs - Edit</h1>
            <h1 v-if="this.$route.path == '/categories'">Categories</h1>
            <h1 v-if="this.$route.path == '/addCategory'">Categories - Add</h1>
            <h1 v-if="this.$route.name == 'updateCategory'">Categories - Edit</h1>
            <button class="nav-btn trash-btn" @click="logOut">
                <font-awesome-icon :icon="['fas', 'xmark']" class="nav-icon trash-icon" />
            </button>
        </nav>
    </header>
</template>

<script>
import NotificationWindow from "@/components/NotificationWindow.vue";
import axios from "axios";
import SwitchLanguage from "./SwitchLanguage.vue";
import { useStore } from "../store/index";
import DropdownComponent from "@/components/ReusableComponents/DropdownComponent.vue";
import DropdownItemGroup from "@/components/ReusableComponents/DropdownItemGroup.vue";
import DropdownItem from "@/components/ReusableComponents/DropdownItem.vue";
import VueCookies from "vue-cookies";

export default {
    name: "NavbarView",
    components: { NotificationWindow, SwitchLanguage, DropdownComponent, DropdownItemGroup, DropdownItem },
    props: {
        toggleSideBarView: Function,
        SidebarViewOpen: Boolean,
    },
    computed: {
        flag() {
            return this.$i18n.locale === "en" ? "united-kingdom_flag.svg" : "france_flag.svg";
        },
    },
    data() {
        return {
            showNotif: false,
            userInitials: "",
            showMenu: false,
            showCompanies: false,
            companies: [],
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        toggleShowCompanies() {
            this.showCompanies = !this.showCompanies;
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
            this.showCompanies = false;
        },
        toggleNotif() {
            this.showNotif = !this.showNotif;
        },
        SwitchLocale() {
            this.$i18n.locale = this.$i18n.locale === "en" ? "fr" : "en";
        },
        loggedIn() {
            axios
                .get("https://server.go-platform.com/user/info", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.Store.setCompanyName(this.$route.query.company_name);
                    const name = res.data.user.name.split(" ");
                    const firstName = name[0];
                    const lastName = name[1];
                    const email = res.data.user.email;
                    let initials = lastName.charAt(0) + "." + firstName.charAt(0);
                    this.userInitials = initials.toUpperCase();
                    const user = { firstName, lastName, email };
                    this.Store.getCompanyCredit();
                    sessionStorage.setItem("user", JSON.stringify(user));
                    //this.$emit('userLogged')
                })
                .catch((err) => {
                    console.log(err);
                    // Handle error if the token is invalid or expired
                    //this.$router.push('/');
                });
            // }
        },

        logOut() {
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://server.go-platform.com/user/logout",
                withCredentials: true,
                headers: {},
            };
            this.Store.isLoading = true;
            axios
                .request(config)
                .then(() => {
                    this.$router.push("/");
                    VueCookies.set("userLogged", false, "24h");

                    this.Store.userLogged();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        switchCompany(companyName) {
            this.Store.isLoading = true;
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "https://server.go-platform.com/company/switchCompany",
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    company_name: companyName,
                },
                withCredentials: true,
            };
            axios
                .request(config)
                .then((response) => {
                    this.$router.push(`/dashboard?company_name=${response.data.company_name}`);
                    setTimeout(() => {
                        this.$router.go("");
                    }, 300);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.loggedIn();
        this.Store.getCompanyCredit();
        axios
            .get("https://server.go-platform.com/users/companies", {
                withCredentials: true,
            })
            .then((res) => {
                this.companies = res.data.companies;
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
</script>

<style scoped lang="scss">
.viewbtn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    border: none;
    border-radius: 15px;
    color: #fff;
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);

    svg {
        fill: none;
        stroke: #fff;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 32px;
    }
}

.viewbtn:hover {
    box-shadow: inset 0px 0px 8px 2px rgb(134, 140, 255, 0.5);
    background: white;
    // border: #868cff 1px solid;
    color: #868cff;

    svg {
        fill: none;
        stroke: #868cff;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 32px;
    }
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 2%;
    /* padding-left: 160px; */
    // padding-right: 3%;
    border-bottom: 2px solid #edeff2;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    // margin-left: 18%;
}

.navbar .DropdownComponent div.account-name {
    display: block !important;
    /* or other styles you need */
    align-items: unset !important;
    gap: unset !important;
}

h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    color: #2196f3;
}

.navbar > :first-child {
    display: flex;
}

.navbar div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

@media screen and (max-width: 991.98px) {
    .navbar div.profile-section {
        display: none;
    }
}

@media screen and (min-width: 991.98px) {
    .navbar div .smallNav {
        display: none !important;
    }
}

/* button {
  border: none;
  background: none;
  padding: 0;
} */

.bell-btn:hover {
    border: 2px solid #2196f3;
}

.logo-btn:hover {
    border: 2px solid #2196f3;
}

.logo-icon {
    color: #2196f3;
}

.bell-btn:hover + .bell-icon {
    color: #fff;
}

.bell-icon {
    color: #2196f3;
}

.trash-icon {
    color: red;
}

.trash-btn:hover {
    border: 2px solid red;
}

.trash-btn:hover + .trash-icon {
    color: red;
}

.nav-btn {
    padding-top: 2px;
}

button img {
    width: 56px;
    height: 56px;
}

input {
    border: none;
}

.logo {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 18px;
    color: #0d47a1;
    margin-left: 4px;
    margin-right: 32px;
}

.nav-links a {
    margin: 0 25px;
    height: 100%;
    font-family: "Roboto";
    color: #0f172a;
    text-decoration: none;
    position: relative;
}

.searchbar {
    height: 40px;
    width: 20vw;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
    border-radius: 30px;
    padding: 0 16px;
    display: none;
}

.searchbar div {
    width: 100%;
}

.searchbar input {
    padding: 0 0.25rem;
    width: 100%;
}

::placeholder {
    /* font-family: "Roboto"; */
    font-weight: 700;
    font-size: 12px;
    color: #adb8cc;
}

input:focus {
    outline: none;
}

.account-name {
    /* margin-right: 16px; */
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    // margin: 0 24px;
    background-image: linear-gradient(120deg, #2196f3 0%, #c084fc 82%);
}

.thelogo {
    &:hover {
        cursor: pointer;
    }
}

.expend {
    margin-left: 5%;
}

.active::after {
    // border-bottom: 2px solid rgb(123, 42, 255);
    // // background: rgb(123, 42, 255);
    // // background: linear-gradient(90deg, rgba(123, 42, 255, 1) 0%, rgba(0, 174, 240, 1) 100%);
    // &::after {
    //     height: 0px;
    // }
    content: "";
    width: 100%;
    height: 2px;
    background: #2196f3;
    position: absolute;
    /* bottom: 0px; */
    top: 155%;
    left: 0;
    transition: width 0.2s ease-in-out;
}

.disabled {
    pointer-events: none;

    span {
        opacity: 0.5;
    }
}
</style>
